$loading-indicator-background: rgba(255, 255, 255, 0.9);

.loading-indicator {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: $loading-indicator-background;
  z-index: 100000;
  padding: 0px;
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}
.percentage-indicator {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  background-color: #e6f5ffd3;
  z-index: 100000;
  border-radius: 20px;
  height: 88vh;

  padding: 0px;
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
}
