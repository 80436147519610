.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgb(0 0 0 / 48%);
}

.sideBar {
  height: 85%;
  overflow: auto;
}

.sideBar::-webkit-scrollbar {
  width: 8px;
}

.sideBar::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
  border-radius: 20px;
}

.sideBar::-webkit-scrollbar-thumb {
  background-color: #0fcbd0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.drawer-icon-btn {
  color: white;
}
