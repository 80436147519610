@font-face {
  font-family: "centra-Regular";
  src: url("./resources/fonts/CentraNo2-Book.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("./resources/fonts/ProximaNovaFont.otf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./resources/fonts/Roboto-Regular.ttf") format("truetype");
}
$all-breakpoints: (
  "small": (
    max-width: 1024px,
  ),
  "large": (
    min-width: 1025px,
  ),
) !default;

/// Mixin to manage responsive breakpoints

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($all-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($all-breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints: #{map-keys($breakpoints)}.";
  }
}

body {
  font-family: "Roboto-Regular", "Helvetica", sans-serif;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #085b74;
  margin: 0px;
  height: 100%;
}
.error-msg {
  color: red;
  margin-top: 10px;
  display: inline-block;
}
@media only screen and (max-width: 800px) {
  .responsive {
    display: none !important;
  }
  .fleet {
    height: 30vh !important;
  }

  .dashboardBody {
    padding: 10px;
    max-height: 75vh;
    overflow: auto;
  }

  .createGroup {
    top: 35%;
    left: 40%;
  }

  .emailField {
    background-color: white;
    border-radius: 5px;
    padding: 5px 5px 5px 10px !important;
    margin: 0px !important;
    width: 97% !important;
  }
  .footer {
    float: right;
  }
  .systemStatus {
    max-height: 77vh !important;
    padding: 10px;
    overflow: auto;
  }
  .networkSlideBlock {
    background-color: #6fa5af;
    box-shadow: 2px 2px 2px 2px #d2e1e3;
    border-radius: 12px;
    width: 90% !important;
    display: inline-block;
    margin: 5px;
  }
}
.tr-hide-mobile {
  @include respond-to("small") {
    display: none !important;
  }
}
.tr-hide-web {
  display: none !important;
  @include respond-to("small") {
    display: inline-block !important;
  }
}

.rolloutDetailsStatusDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 800px) {
  .dashboardBody {
    padding: 10px;
    max-height: 75vh;
    overflow: auto;
  }

  .emailText {
    position: relative;
    top: 15px;
    padding-left: 25px;
  }
  .emailField {
    background-color: white;
    border-radius: 5px;
    padding: 5px 5px 5px 10px !important;
    margin: 5px 5px 5px -40px !important;
    width: 97% !important;
  }
  .footer {
    float: right;
    position: relative;
    top: -15px;
    left: -35px;
  }
  .systemStatus {
    max-height: 80vh !important;
    padding: 10px;
    overflow: auto;
  }
  .networkSlideBlock {
    background-color: #6fa5af;
    box-shadow: 2px 2px 2px 2px #d2e1e3;
    border-radius: 12px;
    width: 45% !important;
    display: inline-block;
    margin: 5px;
    margin-right: 10px;
  }
}
.createGroup {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  background-color: #cbdfe3;
  border: none;
  box-shadow: 24px;
  border-radius: 20px;
}
.userPopUp {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #cbdfe3;
  border: none;
  box-shadow: 24px;
  border-radius: 10px;
}
.createGroupFields {
  background-color: white;
  border-radius: 10px;
  padding: 2px 2px 2px 7px !important;
}

.createGroupFieldsError {
  background-color: white;
  border-radius: 5px;
  padding: 2px 2px 2px 7px !important;
  border: 1px solid red !important;
}

.fleet {
  background-color: white;
  border-radius: 15px;
  height: 15vh;
  overflow: auto;
}

.apexcharts-series path {
  position: sticky;
  clip-path: inset(0% 0% 0% 0% round 10px);
}

.footerImg {
  background: url(./resources/icons/Footer.png) no-repeat;
  background-size: cover;
  background-position: 50% 90%;
  height: 1%;
}
.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.MuiTableRow-head th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.MuiTableRow-head th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.MuiTablePagination-root {
  border-radius: 10px;
}
.modal-box {
  max-height: 80vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modal-box::-webkit-scrollbar {
  display: none;
}
.MuiPaper-elevation1 {
  box-shadow: none;
}
.MuiInputBase-input {
  color: #06415d !important;
}
.devicesGroup {
  display: flex;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}
.real-time-graph {
  .graph-content {
    height: 80vh;
    overflow: auto;
    padding: 20px;
    .filter-options {
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: 800px) {
        flex-direction: column;
      }
      .toggle-container {
        padding: 15px;
        width: fit-content;
        background-color: white;
        border-radius: 15px;
        display: flex;
        @media only screen and (max-width: 800px) {
          flex-direction: column;
          margin-bottom: 1em;
        }
        .toggle-tab-active {
          padding: 5px;
          background-color: #08cacfde;
          border-radius: 30px;
          padding: 5px 15px 5px 15px;
          color: white;
          cursor: pointer;
        }
        .toggle-tab-inActive {
          padding: 5px;
          background-color: white;
          border-radius: 30px;
          padding: 5px 15px 5px 15px;
          color: black;
          cursor: pointer;
        }
      }
    }
    .dropDown {
      height: 50px;
      border-radius: 15px;
      margin: 0px 0px 0px 15px;
      @media only screen and (max-width: 800px) {
        height: 0px;
        margin: 0px;
      }
    }
    .apply-btn {
      text-transform: none;
      background-color: #0fcbd0;
      color: white;
      border-radius: 10px;
      margin: 5px 0px 0px 0px;
      height: 48px;
    }
    .graph-container {
      margin-top: 10px;
      overflow: auto;
      height: 80vh;
      @media only screen and (max-width: 800px) {
        height: 50vh;
      }
    }
  }
}
