.main-container {
  padding: 3em 3em;
}
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  .search {
    background-color: white;
    border-radius: 20px;
    width: 95%;
    padding: 0.5em !important;
  }
}
.table-container {
  background-color: white;
  border-radius: 10px;
  padding: 0.2em 0.5em 0.2em 0.5em;
  margin: 10px;
}
.table-header {
  display: flex;
  .column {
    display: flex;
    justify-content: center;
    color: #06415d;
  }
}
.table-body {
  max-height: 60vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  .body-content {
    font-weight: lighter;
    align-items: center;
  }
}

.img {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.table-body::-webkit-scrollbar-thumb {
  background-color: #d7dada; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.table-body::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  @media only screen and (min-width: 2000) {
    display: none;
  }
}
.MuiInputBase-input {
  padding-left: 1em !important;
}
.more-detail {
  background-color: #f8f8f8;
  border-radius: 0px 0px 10px 10px;
  padding: 1em;
  min-height: 4em;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  p {
    color: #06415d;
    margin: 0px;
    font-weight: normal;
  }
}
.btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.message-wrapper {
  display: flex;
  justify-content: center;
}
