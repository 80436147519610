.custom_select__control {
  background-color: #e3ecf2 !important;
  border-radius: 24px !important;
  padding: 3px !important;
  border: none !important;
  width: 80% !important;
  font-weight: 100 !important;
}
.custom_select__placeholder {
  color: #87a4b3 !important;
}
